import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'input',
    'link',
  ]

  onShowPicker(event) {
    this.inputTarget.focus() // show native picker
  }

  onChange(event) {
    const date = this.inputTarget.value
    const url = this.element.dataset.calendarUrl.replace('DATE', date)
    this.linkTarget.href = url
    this.linkTarget.click()
  }
}
