import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = ['more']

  connect() {
  }

  toggleMore() {
    if (this.hasMoreTarget) {
      toggle(this.moreTarget)
    }
  }
}
