import { Controller } from '@hotwired/stimulus'
import { show, hide, toggle } from 'pistachio'

export default class extends Controller {

  static targets = [
    'toggle',
    'tasks',
    'finishedTask',
    'confirmSkip',
    'taskDescription',
    'progressMeter',
  ]

  connect() {
    this.dropdownWindow =  this.element.querySelector('.c-dropdown__window')

    hide(this.confirmSkipTarget)

    // Show getting started automatically if we have a finished task
    if (this.hasFinishedTaskTarget) {
      hide(this.tasksTarget)

      // show getting started when it has a finished task
      this.showTimeout = setTimeout(() => {
        this.toggleTarget.click()
      }, 1000)
    }

    // Hide description targets initially
    this.taskDescriptionTargets.forEach((el, i) => hide(el))

    // Animate progress bar
    const percent = this.progressMeterTarget.getAttribute('data-percent-done')
    const previousPercent = this.progressMeterTarget.getAttribute('data-previous-percent-done')

    if (previousPercent) {
      this.progressMeterTarget.style.width = `${previousPercent}%`

      setTimeout(() => {
        this.progressMeterTarget.style.transition = 'width 0.5s linear'
        this.progressMeterTarget.style.width = `${percent}%`
      }, 1500)
    } else {
      this.progressMeterTarget.style.width = `${percent}%`
    }
  }

  opened() {
    // if show timeout is active, cancel it
    if (this.showTimeout) {
      clearTimeout(this.showTimeout)
      this.showTimeout = null
    }
  }

  closed() {
    // switch to task upon closing of dropdown
    // (except for welcome message)
    if (this.hasFinishedTaskTarget) {
      this.backToList()
    }
  }

  toggleTask(event) {
    this.taskDescriptionTargets.forEach((el) => {
      if (el.closest('li') === event.target.closest('li')) {
        toggle(el)
      } else {
        hide(el)
      }
    })
  }

  skip() {
    show(this.confirmSkipTarget)
    hide(this.tasksTarget)
  }

  backToList() {
    if (this.hasFinishedTaskTarget) {
      hide(this.finishedTaskTarget)
    }

    hide(this.confirmSkipTarget)
    show(this.tasksTarget)
  }

  get dropdownController() {
    return this.application.getControllerForElementAndIdentifier(this.element, '.c_gs__dropdown')
  }

}
