import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'label',
    'range',
    'startDate',
    'endDate',
  ]

  connect() {
    this.update()
  }

  onSelectRange(event) {
    const li = event.target.closest('[data-start-date]')
    const startDate = li?.dataset?.startDate
    const endDate = li?.dataset?.endDate

    if (startDate && endDate) {
      Helpers.emit(this.startDateTarget, 'date:set!', startDate)
      Helpers.emit(this.endDateTarget, 'date:set!', endDate)
    } else {
      this.startDateTarget.parentElement.querySelector('input[type="text"]').focus()
    }
  }

  update() {
    const startDate = this.startDateTarget.value
    const endDate = this.endDateTarget.value

    const custom = this.rangeTargets.find(range => !range.dataset.startDate && !range.dataset.endDate)
    const selected = this.rangeTargets.find(range => range.dataset.startDate === startDate && range.dataset.endDate === endDate)

    this.labelTarget.textContent = selected?.dataset.label || custom.dataset.label
  }

  onDateChanged() {
    this.update()
  }
}
