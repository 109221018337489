import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  onStore(event) {
    if (event.target.hasAttribute('data-preserve-scroll')) {
      window.turboScrollTop = document.querySelector('main').scrollTop
      document.addEventListener('turbo:before-render', this.disableTurboScroll)
    }

  }

  onLoad(event) {
    if (window.turboScrollTop) {
      document.querySelector('main').scrollTo(0, window.turboScrollTop)
      document.removeEventListener('turbo:before-render', this.disableTurboScroll)
    }
    window.turboScrollTop = undefined
  }

  // Turbo is setting the scroll position to 0,0 after the `turbo:load` event.
  // This is why we can't just set the scroll position after `turbo:load` is called.
  // This (hopefully temporary) hack tells Turbo that it has already scrolled so it doesn't scroll
  // to 0,0 and we can set the scroll position ourselves.
  // It might break in a future relaase because it uses an internal API of Turbo,
  // but we have a spec for it (absences_spec.rb => "preserve scroll position").
  // https://github.com/hotwired/turbo/issues/37#issuecomment-979504088
  disableTurboScroll() {
    if (Turbo.navigator.currentVisit) {
      Turbo.navigator.currentVisit.scrolled = true
    }
  }
}
