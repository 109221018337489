import { Controller } from '@hotwired/stimulus'
import { show, hide, isVisible } from '../../../utils/visibility'
import { Helpers } from '../../../utils/helpers'

import {
  computePosition,
  flip,
  shift,
  autoUpdate,
  arrow,
  offset,
} from '@floating-ui/dom'

export default class extends Controller {
  connect() {
    // create element
    this.tooltip = document.createElement('div')
    this.tooltip.className = 'c-tooltip dropdown'
    document.body.appendChild(this.tooltip)

    this.arrow = document.createElement('div')
    this.arrow.setAttribute('data-arrow', '')

    // add handlers
    this.element.addEventListener('click', (event) => {
      if (event.target.getAttribute && event.target.getAttribute('data-click-tooltip')) {
        event.stopPropagation()
        if (!this.tooltip || !isVisible(this.tooltip) || this.tooltip.source != event.target) {
          this.show(event.target)
          return false
        } else {
          this.hide()
        }
      } else {
        // clicking outside hides
        if (this.tooltip && isVisible(this.tooltip)) {
          this.hide()
        }
      }
    }, true) // mousedown

    // disable hover tooltips on mobile
    if (!Helpers.deviceHasNativeFormControls()) {
      this.element.addEventListener('mouseenter', (event) => {
        if (event.target.getAttribute && event.target.getAttribute('data-tooltip')) {
          this.show(event.target)
        }
      }, true) // mouseenter

      this.element.addEventListener('mouseleave', (event) => {
        if (event.target === this.tooltip && this.tooltip.getAttribute('data-hide-on-click') === 'false') {
          this.hide()
        }
        if (event.target.getAttribute && event.target.getAttribute('data-tooltip')) {
          this.hide()
        }
      }, true) // mouseleave
    }
  }

  disconnect() {
    this.tooltip.remove()
  }

  show(source) {
    this.tooltip.source = source
    this.tooltip.setAttribute('data-hide-on-click', !!source.getAttribute('data-click-tooltip'))
    this.tooltip.innerHTML = this.arrow.outerHTML + (source.getAttribute('data-tooltip') || source.getAttribute('data-click-tooltip'))
    show(this.tooltip)

    this.cleanupTooltip = autoUpdate(source, this.tooltip, () => {
      computePosition(source, this.tooltip, {
        placement: 'top',
        middleware: [
          flip(),
          offset(12),
          shift({ padding: 10 }),
          arrow({element: this.tooltip.querySelector('[data-arrow]')})
        ],
      })
      .then(({x, y, middlewareData, placement}) => {
        Object.assign(this.tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        this.tooltip.setAttribute('data-placement', placement)

        if (middlewareData.arrow) {
          const {x, y} = middlewareData.arrow;

          Object.assign(this.tooltip.querySelector('[data-arrow]').style, {
            left: x != null ? `${x}px` : '',
            top: y != null ? `${y}px` : '',
          });
        }
      })
    })
  }

  hide() {
    hide(this.tooltip)
    this.cleanupTooltip()
  }
}
