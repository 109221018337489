import { Controller } from '@hotwired/stimulus'
import Croppie from 'croppie'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'file',
    'avatar',
    'modal',
    'canvas',
    'imageData',
    'form',
  ]

  connect() {
    this.avatarSize = 180
  }

  disconnect() {
    if (this.croppie) {
      this.croppie.destroy()
    }
  }

  readFile() {
    let files = this.fileTarget.files

    if (!files || !files[0]) {
      return
    }

    let reader = new FileReader()
    reader.onload = e => {
      if (!this.croppie) {
        this.croppie = new Croppie(this.canvasTarget, {
          viewport: {
            width: this.avatarSize,
            height: this.avatarSize,
            type: 'circle',
          },
        })
      }

      this.croppie.bind({ url: e.target.result })
      Helpers.emit(this.modalTarget, 'modal:open!')
    }
    reader.readAsDataURL(files[0])
  }

  apply() {
    this.croppie
      .result({
        type: 'base64',
        size: 'viewport',
        circle: false,
      })
      .then(result => {
        // set form field
        this.imageDataTarget.value = result

        // auto submit
        this.formTarget.submit()
      })
  }
}
