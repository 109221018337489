import { Controller } from '@hotwired/stimulus'
import { toggle, Helpers } from 'pistachio'

export default class extends Controller {
  static targets = []

  onToggleDisplayOnlySums(event) {
    const url = event.target.dataset.url
    if (url) {
      Helpers.visit(url)
    }
  }
}
