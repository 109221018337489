import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  CSS_ROW_CLASS = '.c-manage_user_vacation-adjustments__row'
  CSS_ROW_OPEN_CLASS = 'c-manage_user_vacation-adjustments__row--open'

  onToggleRows(event) {
    event.target.closest(this.CSS_ROW_CLASS).classList.toggle(this.CSS_ROW_OPEN_CLASS)
  }

}
