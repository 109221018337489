import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle, show, hide } from 'pistachio'

export default class extends Controller {
  static targets = [
    'groups',
    'users',
    'fromField',
    'toField',
    'range',
    'period',
    'processedAt',
  ]

  connect() {
    this.userToggle = this.element.dataset.initialUserToggle
    this.dateToggle = this.element.dataset.initialDateToggle
    this.update()
  }

  onToggleUserFilter() {
    this.userToggle = this.userToggle === 'groups' ? 'users' : 'groups'
    this.update()
  }

  onToggleDateFilter() {
    this.dateToggle = this.dateToggle === 'processed_at' ? 'period' : 'processed_at'
    this.update()
  }

  onPeriodSet() {
    const value = event.detail.value

    // If the selected period has a value (predefined ranges),
    // hide the from/to fields and update them.
    // Otherwise, show from/to fields.
    if (value) {
      // predefined
      hide(this.rangeTarget)
      const range = value.split(',')
      Helpers.emit(this.fromFieldTarget, 'date:set!', range[0])
      Helpers.emit(this.toFieldTarget, 'date:set!', range[1])
    } else {
      // custom
      show(this.rangeTarget)
    }
  }

  update() {
    const showGroups = this.hasGroupsTarget && this.userToggle === 'groups'
    if (this.hasGroupsTarget) { Helpers.toggleVisibilityAndState(this.groupsTarget, showGroups) }
    Helpers.toggleVisibilityAndState(this.usersTarget, !showGroups)

    const showProcessedAt = this.hasProcessedAtTarget && this.dateToggle === 'processed_at'
    if (this.hasProcessedAtTarget) { Helpers.toggleVisibilityAndState(this.processedAtTarget, showProcessedAt) }
    Helpers.toggleVisibilityAndState(this.periodTarget, !showProcessedAt)
  }
}
