import { Controller } from '@hotwired/stimulus'
import { ChartHelpers, d3 } from 'utils/chart_helpers'

export default class extends Controller {
  connect() {
    const XAXIS_HEIGHT = 25

    const width = parseInt(this.element.dataset.width)
    const height = parseInt(this.element.dataset.height)

    const svg = d3.select(this.element)
      .append('svg')
      .attr('width', `${width}px`)
      .attr('height', `${height}px`)

    const values = JSON.parse(this.element.dataset.weeklyOvertimes)
    const [min, max] = JSON.parse(this.element.dataset.bounds)

    const numWeeks = Math.max(values.length, 52)

    const x = d3.scaleBand()
      .domain(d3.range(0, numWeeks))
      .range([0, width])
      .paddingInner(0.2)

    const y = d3.scaleLinear()
      .domain([min, max])
      .range([height - XAXIS_HEIGHT, 0])
      .clamp(true)

    ChartHelpers.addTooltipHandler(this.element)

    // Background
    ChartHelpers.drawMonthBars(svg, x, y)
    ChartHelpers.drawZeroLine(svg, x, y)


    // Data
    svg.selectAll('.bar')
      .data(values)
      .enter()
      .append('rect')
      .attr('x', (d, i) => x(i))
      .attr('width', x.bandwidth())
      .attr('y', (d, i) => y(Math.max(d.overtime, 0)))
      .attr('height', (d, i) => Math.abs(y(d.overtime) - y(0)))
      .attr('class', (d, i) => `bar ${d.status}`)

    // Limit lines
    const limits = JSON.parse(this.element.dataset.limits)
    limits.forEach(limit => {
      svg.append('line')
        .attr('x1', x.range()[0])
        .attr('x2', x.range()[1])
        .attr('y1', y(limit))
        .attr('y2', y(limit))
        .attr('class', 'limit')
    })

    // Hover bars + tooltips
    ChartHelpers.drawHoverBars(svg, x, y, values)

    // X Axis
    ChartHelpers.drawMonthAxis(svg, x, y)
  }
}
