.c-manage_user_charts_statutory_overtimes {
    position: relative;
} /* for year selector */

  .c-manage_user_charts_statutory_overtimes .c-manage_user_charts_year-selector {
    display: none;
}

  .c-manage_user_charts_statutory_overtimes:hover .c-manage_user_charts_year-selector {
    display: inline-block;
}

  .c-manage_user_charts_statutory_overtimes .bar {
    fill: #3b82f6;
  }

  .c-manage_user_charts_statutory_overtimes .bar.warning {
      fill: #facc15;
    }

  .c-manage_user_charts_statutory_overtimes .bar.danger {
      fill: #fca5a5;
    }

  .c-manage_user_charts_statutory_overtimes .limit {
    stroke: #a1a1aa;
    stroke-dasharray: 2, 1;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL3VzZXJzL3VzZXIvY2hhcnRzL3N0YXR1dG9yeV9vdmVydGltZXNfY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtJQUFBO0FBQWUsRUFBRSxzQkFBc0I7O0VBRUQ7SUFBQTtBQUFhOztFQUNIO0lBQUE7QUFBbUI7O0VBRW5FO0lBQ0UsYUFBOEI7RUFTaEM7O0VBUEU7TUFDRSxhQUFnQztJQUNsQzs7RUFFQTtNQUNFLGFBQTZCO0lBQy9COztFQUdGO0lBQ0UsZUFBZ0M7SUFDaEMsc0JBQXNCO0VBQ3hCIiwiZmlsZSI6ImFwcC9jb21wb25lbnRzL3VzZXJzL3VzZXIvY2hhcnRzL3N0YXR1dG9yeV9vdmVydGltZXNfY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jLW1hbmFnZV91c2VyX2NoYXJ0c19zdGF0dXRvcnlfb3ZlcnRpbWVzIHtcbiAgQGFwcGx5IHJlbGF0aXZlOyAvKiBmb3IgeWVhciBzZWxlY3RvciAqL1xuXG4gIC5jLW1hbmFnZV91c2VyX2NoYXJ0c195ZWFyLXNlbGVjdG9yIHsgQGFwcGx5IGhpZGRlbjsgfVxuICAmOmhvdmVyIHsgLmMtbWFuYWdlX3VzZXJfY2hhcnRzX3llYXItc2VsZWN0b3IgeyBAYXBwbHkgaW5saW5lLWJsb2NrOyB9IH1cblxuICAuYmFyIHtcbiAgICBmaWxsOiB0aGVtZSgnY29sb3JzLmJsdWUuNTAwJyk7XG5cbiAgICAmLndhcm5pbmcge1xuICAgICAgZmlsbDogdGhlbWUoJ2NvbG9ycy55ZWxsb3cuNDAwJyk7XG4gICAgfVxuXG4gICAgJi5kYW5nZXIge1xuICAgICAgZmlsbDogdGhlbWUoJ2NvbG9ycy5yZWQuMzAwJyk7XG4gICAgfVxuICB9XG5cbiAgLmxpbWl0IHtcbiAgICBzdHJva2U6IHRoZW1lKCdjb2xvcnMuZ3JheS40MDAnKTtcbiAgICBzdHJva2UtZGFzaGFycmF5OiAyLCAxO1xuICB9XG59XG4iXX0= */