import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = ['subscribeButton', 'extendTrialLink', 'waitingMessage']

  extendTrial() {
    if (this.hasSubscribeButtonTarget)
      this.subscribeButtonTarget.classList.add('hidden')

    if (this.hasExtendTrialLinkTarget)
      this.extendTrialLinkTarget.classList.add('hidden')

    if (this.hasWaitingMessageTarget)
      this.waitingMessageTarget.classList.remove('hidden')
  }
}
