import { Preferences } from 'preferences'
import dayjs from 'dayjs'
import 'dayjs/locale/de'

export class DateUtils {
  static dayjs(dateStringOrObject = undefined) {
    return dayjs(dateStringOrObject).locale(window.lang)
  }

  static formatISO(date) {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return utcDate.toISOString().substring(0, 10) // :stare:
  }

  static formatHHMM(date) {
    const hh = `0${date.getHours()}`.slice(-2)
    const mm = `0${date.getMinutes()}`.slice(-2)

    return `${hh}:${mm}`
  }

  static timeDiff(startTime, endTime) {
    if (!startTime || !endTime) {
      return 0
    }

    const date = this.formatISO(new Date())
    const start = new Date(`${date}T${startTime}`)
    const end = new Date(`${date}T${endTime}`)

    while (start > end) {
      end.setDate(end.getDate() + 1)
    }

    return end.valueOf() / 1000 - start.valueOf() / 1000
  }

  static secondsToDuration(seconds) {
    if (Preferences.durationFormat === 'decimal') {
      return this.secondsToDurationDecimal(seconds || 0)
    } else {
      return this.secondsToDurationHHMM(seconds || 0)
    }
  }

  static secondsToDurationDecimal(seconds) {
    const hours = seconds / 3600.0
    return hours.toFixed(2)
  }

  static secondsToDurationHHMM(seconds) {
    const prefix = seconds < 0 ? '-' : ''

    // use absolute value here since we subtract to get minutes
    const absoluteSeconds = Math.abs(seconds)
    const hours = Math.floor(absoluteSeconds / 3600)
    const minutes = Math.floor((absoluteSeconds - hours * 3600) / 60)

    return `${prefix}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
  }

  static durationToSeconds(duration) {
    if (duration.includes(':')) {
      const durationArray = duration.split(':')
      const hours = parseInt(durationArray[0], 10)
      const minutes = parseInt(durationArray[1], 10)

      return hours * 3600 + minutes * 60
    } else {
      return Math.floor(parseFloat(duration) * 3600.0)
    }
  }

  static timeInSeconds(time) {
    const parts = time.split(':')
    const hours = parseInt(parts[0], 10)
    const minutes = parseInt(parts[1], 10)

    return hours * 3600 + minutes * 60
  }

  static secondsInTime(seconds) {
    let hours = Math.floor(seconds / 3600)
    let minutes = Math.floor((seconds - hours * 3600) / 60)

    if (hours < 10) { hours = `0${hours}` }
    if (minutes < 10) { minutes = `0${minutes}` }

    return `${hours}:${minutes}`
  }

  static setDuration(element, seconds) {
    let color = ''

    const duration = DateUtils.secondsToDuration(seconds);

    element.innerHTML = duration
    // save hhmm for calculations (otherwise sums of decimal durations lead to rounding issues)
    element.dataset.hhmmDuration = DateUtils.secondsToDurationHHMM(seconds)
  }

  static parseTime(str) {
    if (!str.trim()) {
      return ''
    }

    const parsed = DateUtils.parseTimeString(str)
    if (!parsed) {
      return undefined
    }

    return DateUtils.formatHHMM(parsed)
  }

  static parseDuration(str) {
    if (!str.trim()) {
      return ''
    }

    // 1,5 -> 1.5
    if (str.indexOf(',') !== -1) {
      str = str.replace(',', '.')
    }

    // handle decimal
    if (str.indexOf('.') !== -1) {
      const hours = parseFloat(str)
      if (hours) {
        const seconds = Math.ceil(hours * 3600)
        return DateUtils.secondsToDuration(seconds)
      }
    }

    // 1h15m -> 1:15
    str = str.replace(/(\d+)h\s*(\d+)m/, '$1:$2')

    // 1h -> 1:00
    str = str.replace(/(\d+)h/, '$1:00')

    // 15m -> 0:15
    str = str.replace(/(\d+)m/, '0:$1')

    // :15 -> 0:15
    str = str.replace(/^:(\d+)/, '0:$1')

    return DateUtils.secondsToDuration(DateUtils.durationToSeconds(str))
  }

  static prettyDateRange(start, end) {
    const startDate = DateUtils.dayjs(start)
    const endDate = DateUtils.dayjs(end)

    const sameYear = startDate.year() == endDate.year()
    const sameMonth = (startDate.month() == endDate.month() && sameYear)

    const endFormat = 'DD. MMM. YYYY'
    let startFormat = endFormat

    if (sameMonth) {
      startFormat = 'DD.'
    }
    else if (sameYear) {
      startFormat = 'DD. MMM.'
    }

    const from = startDate.format(startFormat)
    const to = endDate.format(endFormat)

    return `${from} – ${to}`
  }

  // Taken from https://github.com/wvega/timepicker ($.fn.timepicker.parseTime)
  // Basically converts a simple string to a guessed time
  // e.g. 12  => 12:00
  //      1   => 01:00
  //      103 => 10:30
  static parseTimeString(str) {
    const patterns = [
      // 1, 12, 123, 1234, 12345, 123456
      [
        /^(\d+)$/,
        "$1"
      ],

      // :1, :2, :3, :4 ... :9
      [
        /^:(\d)$/,
        "$10"
      ],

      // :1, :12, :123, :1234 ...
      [
        /^:(\d+)/,
        "$1"
      ],

      // 6:06, 5:59, 5:8
      [
        /^(\d):([7-9])$/,
        "0$10$2"
      ],
      [
        /^(\d):(\d\d)$/,
        "$1$2"
      ],
      [
        /^(\d):(\d{1,})$/,
        "0$1$20"
      ],

      // 10:8, 10:10, 10:34
      [
        /^(\d\d):([7-9])$/,
        "$10$2"
      ],
      [
        /^(\d\d):(\d)$/,
        "$1$20"
      ],
      [
        /^(\d\d):(\d*)$/,
        "$1$2"
      ],

      // 123:4, 1234:456
      [
        /^(\d{3,}):(\d)$/,
        "$10$2"
      ],
      [
        /^(\d{3,}):(\d{2,})/,
        "$1$2"
      ],

      //
      [
        /^(\d):(\d):(\d)$/,
        "0$10$20$3"
      ],
      [
        /^(\d{1,2}):(\d):(\d\d)/,
        "$10$2$3"
      ]
    ]
    const length = patterns.length

    const time = new Date()
    let am = false
    let pm = false
    let h = false
    let m = false
    let s = false

    if (typeof str === 'undefined' || !str.toLowerCase) { return null }

    str = str.toLowerCase()
    am = /a/.test(str)
    pm = am ? false : /p/.test(str)
    str = str.replace(/[^0-9:]/g, '').replace(/:+/g, ':')
    let k = 0

    while (k < length) {
      if (patterns[k][0].test(str)) {
        str = str.replace(patterns[k][0], patterns[k][1])
        break
      }
      k++
    }
    str = str.replace(/:/g, '')

    if (str.length === 1) {
      h = str
    } else if (str.length === 2) {
      h = str
    } else if (str.length === 3 || str.length === 5) {
      h = str.substr(0, 1)
      m = str.substr(1, 2)
      s = str.substr(3, 2)
    } else if (str.length === 4 || str.length > 5) {
      h = str.substr(0, 2)
      m = str.substr(2, 2)
      s = str.substr(4, 2)
    }

    if (str.length > 0 && str.length < 5) {
      if (str.length < 3) {
        m = 0
      }
      s = 0
    }

    if (h === false || m === false || s === false) {
      return false
    }

    h = parseInt(h, 10)
    m = parseInt(m, 10)
    s = parseInt(s, 10)

    if (am && h === 12) {
      h = 0
    } else if (pm && h < 12) {
      h = h + 12
    }

    if (h > 24 && (h % 10) <= 6 && m <= 60 && s <= 60) {
      if (str.length >= 6) {
        return DateUtils.parseTimeString(str.substr(0, 5))
      } else {
        return DateUtils.parseTimeString(str + '0' + (am ? 'a' : '') + (pm ? 'p' : ''))
      }
    } else if (h <= 24 && m <= 60 && s <= 60) {
      time.setHours(h, m, s)
      return time
    }
    else {
      return false
    }
  }
}

export default DateUtils
