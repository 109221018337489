import { Controller } from "@hotwired/stimulus";
import { Helpers, isVisible } from 'pistachio'

export default class extends Controller {
  static targets = [
    'filter',
  ]

  onRemoveFilter(event) {
    const filter = this.filterTargets.find(filter => filter.contains(event.target))
    Helpers.toggleVisibilityAndState(filter, false)
  }

  onAddFilterSet(event) {
    const option = event.detail
    if (!option) return

    const filter = this.filterTargets.find(filter => filter.dataset.filter === option.value)

    if (!isVisible(filter)) {
      Helpers.toggleVisibilityAndState(filter, true)
      // show at end of filter list, order instead of reordering DOM nodes (-> stimulus reconnects)
      filter.style.order = this.filterTargets.filter(filter => isVisible(filter)).length + 1
    }
    filter.querySelector('input[type="text"]').focus()
  }
}
