import { Controller } from '@hotwired/stimulus'
import { toggle, isVisible } from 'pistachio'

export default class extends Controller {
  static targets = [
    'addLink',
  ]

  connect() {
    this.update()
  }

  update() {
    if (this.hasAddLinkTarget) {
      const link = this.addLinkTarget
      if (!link.dataset.originalRemoteModalUrl) {
        link.dataset.originalRemoteModalUrl = link.dataset.remoteModalUrl
      }

      const activeTab = this.element.querySelector('input[name="documents"]:checked')
      if (activeTab) {
        const newUrl = link.dataset.originalRemoteModalUrl.replace('CATEGORY', activeTab.value)
        link.dataset.remoteModalUrl = newUrl
      }
    }
  }
}
