import { Controller } from '@hotwired/stimulus'
import DateUtils from 'date_utils'

export default class extends Controller {
  onTimeBlur(event) {
    const input = event.target
    const parsedTime = DateUtils.parseTime(input.value)
    input.value = parsedTime ? parsedTime : ''
  }
}
