import { Controller } from '@hotwired/stimulus'
import { toggle, isVisible } from 'pistachio'

export default class extends Controller {
  static targets = [
    'confirmSelection',
    'confirmAll',
    'toggleAll',
    'toggleRange',
    'toggle',
    'form',
  ]

  connect() {
    this.loadDisplay()
    this.update()
  }

  onToggleAll(event) {
    const check = event.target.checked
    this.toggleTargets.forEach(checkbox => checkbox.checked = check)
    this.update()
  }

  onToggleRange(event) {
    const check = event.target.checked
    this.toggleTargets.forEach(checkbox => {
      if (checkbox.dataset.range == event.target.dataset.range) {
        checkbox.checked = check
      }
    })
    this.update()
  }

  onToggle(event) {
    this.update()
  }

  onConfirmAll(event) {
    this.toggleTargets.forEach(checkbox => checkbox.checked = true)
    // default behavior leads to submit (confirm all will be disabled), no need to form.submit()
  }

  update() {
    const allChecked = this.toggleTargets.every(checkbox => checkbox.checked)
    const anyChecked = this.toggleTargets.some(checkbox => checkbox.checked)

    if (this.hasConfirmSelectionTarget && this.hasConfirmAllTarget) {
      toggle(this.confirmSelectionTarget, anyChecked)
      toggle(this.confirmAllTarget, !anyChecked)
    }

    if (this.hasToggleAllTarget) {
      this.toggleAllTarget.checked = allChecked
    }

    this.toggleRangeTargets.forEach(rangeCheckbox => {
      rangeCheckbox.checked = this.toggleTargets.filter(checkbox => checkbox.dataset.range == rangeCheckbox.dataset.range).every(checkbox => checkbox.checked)
    })

    // toggle sexy selection border
    this.toggleRangeTargets.forEach(checkbox => {
      checkbox.closest('tr').classList.toggle('selected', checkbox.checked)
    })

    this.toggleTargets.forEach(checkbox => {
      checkbox.closest('tr').classList.toggle('selected', checkbox.checked)
    })
  }

  saveDisplay() {
    this.element.querySelectorAll('[data-toggle]').forEach(toggledEl => {
      sessionStorage.setItem(`work_confirmation_toggle_${toggledEl.dataset.toggle}`, isVisible(toggledEl))
    })
  }

  loadDisplay() {
    this.element.querySelectorAll('[data-toggle]').forEach(toggledEl => {
      const val = sessionStorage.getItem(`work_confirmation_toggle_${toggledEl.dataset.toggle}`)
      if (val === 'true') {
        toggle(toggledEl, true)
      }
    })
  }
}
