import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static values = {
    payload: Object,
  }

  connect() {
    Helpers.emit(this.element, 'shifts:update!', this.payloadValue)
  }
}
