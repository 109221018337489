import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'value',
    'copyLink',
  ]

  onCopy() {
    if (this.valueTarget.type === 'password') {
      this.valueTarget.type = 'text'
    }

    this.valueTarget.select()
    document.execCommand('copy')
    this.copyLinkTarget.innerHTML = this.copyLinkTarget.dataset.copiedContent
  }

  onFocus() {
    this.valueTarget.select()
  }

  onSelect() {
    this.valueTarget.select()
  }

}
