import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'form',
    'search',
    'selection',
    'filter',

    'checkAll',
    'checkItem',
  ]

  connect() {
    this.selectedFilterName = null
    this.update()
  }

  onChange(event) {
    this.update()
  }

  onSearch(event) {
    const searchField = event.target

    let anyMatch = false
    this.selectedFilter.querySelectorAll('label').forEach(label => {
      const isMatch = label.textContent.toLowerCase().includes(searchField.value.toLowerCase())
      anyMatch = anyMatch || isMatch
      toggle(label, isMatch)
    })

    const noResults = this.selectedFilter.querySelector('[data-no-results]')
    toggle(noResults, !anyMatch)
  }

  onCheckAll(event) {
    const checkboxes = this.checkItemTargets.filter(checkbox => this.selectedFilter.contains(checkbox))
    const allChecked = Array.from(checkboxes).every(checkbox => checkbox.checked)
    checkboxes.forEach(checkbox => checkbox.checked = !allChecked)
    this.update()
  }

  update() {
    toggle(this.selectionTarget, this.selectedFilterName === null)

    if (this.selectedFilter) {
      const checkAll = this.checkAllTargets.find(checkbox => this.selectedFilter.contains(checkbox))
      const checkboxes = this.checkItemTargets.filter(checkbox => this.selectedFilter.contains(checkbox))
      const allChecked = Array.from(checkboxes).every(checkbox => checkbox.checked)
      checkAll.checked = allChecked
    }

    this.filterTargets.forEach(filter => Helpers.toggleVisibilityAndState(filter, filter === this.selectedFilter))
  }

  onToggle(event) {
    this.selectedFilterName = null

    this.filterTargets.forEach(filter => {
      filter.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
        if (checkbox.checked) {
          this.selectedFilterName = filter.dataset.filterName
        }
      })
    })
    this.update()
    this.focusSearch()
  }

  onReset(event) {
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = false)
    this.formTarget.submit()
  }

  onSelectFilter(event) {
    this.selectedFilterName = event.target.dataset.filterName
    this.update()

    this.focusSearch()
  }

  onBackToSelection(event) {
    this.selectedFilterName = null
    this.update()
  }

  focusSearch() {
    const searchField = this.searchTargets.find(search => search.dataset.filterName === this.selectedFilterName)
    setTimeout(() => searchField?.focus())
  }


  get selectedFilter() {
    return this.filterTargets.find(filter => filter.dataset.filterName === this.selectedFilterName)
  }


}
