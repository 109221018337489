import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'typeSelect',

    'optionsField',
    'options',
    'optionTemplate',
  ]

  connect() {
    const options = JSON.parse(this.element.dataset.options)
    options.forEach(option => this.addOption(option))

    if(options.length == 0) {
      this.addOption()
    }

    this.update()
  }

  onAddOption(event) {
    this.addOption()
  }

  onRemoveOption(event) {
    event.target.closest('li').remove()
  }

  onTypeSet(event) {
    this.update()
  }

  update() {
    this.element.querySelectorAll('[data-showtype]').forEach(el => toggle(el, false))
    this.element.querySelectorAll(`[data-showtype="${this.typeSelectTarget.value}"]`).forEach(el => toggle(el, true, 'contents'))
  }

  addOption(value = null) {
    const opt = Helpers.clone(this.optionTemplateTarget)
    opt.dataset.target = ''
    opt.querySelector('input').value = value
    this.optionsTarget.append(opt)

    Helpers.toggleVisibilityAndState(opt, true)
  }
}
