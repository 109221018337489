import { line, area, curveMonotoneX } from 'd3-shape'
import { axisBottom, axisLeft } from 'd3-axis'
import { scaleBand, scaleLinear } from 'd3-scale'
import { select } from 'd3-selection'
import { range } from 'd3-array'

export default {
  line: line,
  area: area,
  curveMonotoneX: curveMonotoneX,

  axisBottom: axisBottom,
  axisLeft: axisLeft,

  scaleBand: scaleBand,
  scaleLinear: scaleLinear,

  select: select,

  range: range,
}
