import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'unitEnabled',
    'unitFields',
    'defaultCost',
    'defaultCostEnabled',
    'defaultCostField',
  ]

  connect() {
    this.update()
  }

  update() {
    const isUnitBased = this.unitEnabledTarget.checked
    toggle(this.unitFieldsTarget, isUnitBased)

    toggle(this.defaultCostTarget, !isUnitBased)
    if (isUnitBased) {
      this.defaultCostEnabledTarget.checked = false
    }

    const hasDefaultCost = this.defaultCostEnabledTarget.checked
    toggle(this.defaultCostFieldTarget, hasDefaultCost)
  }
}
