import { Controller } from "@hotwired/stimulus";
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'moduleEnabled',
    'content'
  ]

  connect() {
    this.update()
  }

  update() {
    Helpers.toggleVisibilityAndState(this.contentTarget, this.moduleEnabledTarget.checked)
  }
}
