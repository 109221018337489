import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'eveningStartHour',
    'eveningEndHour',
    'nightStartHour',
    'nightEndHour',
  ]

  onHourSelect(event) {
    const input = event.target.querySelector('input')
    if (input == this.nightStartHourTarget) {
      this.eveningEndHourTarget.value = input.value
      Helpers.emit(this.eveningEndHourTarget, 'select:update!')
    }
    if (input == this.eveningEndHourTarget) {
      this.nightStartHourTarget.value = input.value
      Helpers.emit(this.nightStartHourTarget, 'select:update!')
    }
  }
}
