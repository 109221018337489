import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [ 'monthlyRow' ]

  connect() {
    console.log('hello')
    console.log(this.monthlyRowTargets)
    if (this.monthlyRowTargets.length > 1) {
      const lastRow = this.monthlyRowTargets[this.monthlyRowTargets.length - 1]
      Helpers.emit(lastRow, 'toggle')
    }
  }
}
