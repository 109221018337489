import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'loadingIndicator',
    'data',

    'report',
  ]

  connect() {
    this.element.querySelector('button[type="submit"]').click()
  }

  onTurboSubmitStart(event) {
    toggle(this.loadingIndicatorTarget, true)
    toggle(this.dataTarget, false)

    // Allow sharing/bookmarking of report url
    const form = event.target
    const queryString = new URLSearchParams(Helpers.getFormDataForXHR(form)).toString()
    const url = new URL(window.location.href)
    url.search = queryString
    window.history.replaceState({}, '', url)
  }

  onTurboSubmitEnd() {
    toggle(this.loadingIndicatorTarget, false)
    toggle(this.dataTarget, true)
  }

}
