import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'responsibilitySelect',
  ]

  connect() {
    this.update()
  }

  update() {
    this.element.querySelectorAll('[data-showresponsibility]').forEach(el => {
      const enabled = el.dataset.showresponsibility == this.responsibilitySelectTarget.value
      toggle(el, enabled, 'contents')
      Helpers.disableFields(el, !enabled)
    })
  }
}
