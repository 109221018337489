import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  onEditForm(event) {
    event.target.closest('tr')
      .querySelectorAll('[data-form]')
      .forEach(el => toggle(el, el.dataset.form === 'edit'))
  }

  onNewForm(event) {
    event.target.closest('tr')
      .querySelectorAll('[data-form]')
      .forEach(el => toggle(el, el.dataset.form === 'new'))
  }
}
