import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'submitButton',
  ]

  validate() {
    let allValid = true

    document.querySelectorAll("input[name*='bexio_id_for_hakuna_task']").forEach(task => {
      const valid = !!task.value
      const wrapper = task.closest('.c-select').parentNode
      wrapper.classList.toggle('invalid', !valid)
      wrapper.closest('tr').classList.toggle('bg-red-100', !valid)
      toggle(wrapper.closest('tr').closest('div'), !valid)

      allValid = allValid && valid
    })

    return allValid
  }

  onSubmit(event) {
    const valid = this.validate()

    if (!valid) {
      this.submitButtonTarget.disabled = false
      event.stopImmediatePropagation()
      event.preventDefault()
    } else {
      this.submitButtonTarget.disabled = true
    }

    return false
  }
}
