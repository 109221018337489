import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'oidcEnabled',
    'oidcFields',
  ]

  connect() {
    this.update()
  }

  update() {
    Helpers.toggleVisibilityAndState(this.oidcFieldsTarget, this.oidcEnabledTarget.checked)
  }
}
