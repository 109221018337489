import { Controller } from '@hotwired/stimulus'
import { DateUtils } from 'date_utils'
import { Helpers } from 'pistachio'

const WORK_DAYS_PER_WEEK = 5.0

export default class extends Controller {
  static targets = [
    'workPerWeek',
    'workPerDay',

    'vacationDays',
    'vacationWeeks',
  ]

  connect() {
    this.recalculateWorkPerDay()
    this.recalculateVacationWeeks()
  }

  onWorkPerWeekChanged(event) {
    this.recalculateWorkPerDay()
  }

  onWorkPerDayChanged(event) {
    this.recalculateWorkPerWeek()
  }

  onVacationDaysChanged(event) {
    this.recalculateVacationWeeks()
  }

  onVacationWeeksChanged(event) {
    this.recalculateVacationDays()
  }

  // private

  recalculateWorkPerWeek(event) {
    let workPerWeek = ''
    const workPerDay = this.workPerDayTarget.value
    if (workPerDay) {
      const workPerDayInSeconds = DateUtils.durationToSeconds(workPerDay) || 0
      workPerWeek = DateUtils.secondsToDuration(workPerDayInSeconds * WORK_DAYS_PER_WEEK)
    }

    Helpers.changeInputValue(this.workPerWeekTarget, workPerWeek)
  }

  recalculateWorkPerDay() {
    let workPerDay = ''
    const workPerWeek = this.workPerWeekTarget.value
    if (workPerWeek) {
      const workPerWeekInSeconds = DateUtils.durationToSeconds(workPerWeek) || 0
      workPerDay = DateUtils.secondsToDuration(workPerWeekInSeconds / WORK_DAYS_PER_WEEK)
    }

    Helpers.changeInputValue(this.workPerDayTarget, workPerDay)
  }

  recalculateVacationDays(event) {
    let vacationDays = ''
    const vacationWeeks = this.vacationWeeksTarget.value
    if (vacationWeeks) {
      vacationDays = +((vacationWeeks * WORK_DAYS_PER_WEEK || 0)).toFixed(3) // + prefix to drop zeroes
    }

    Helpers.changeInputValue(this.vacationDaysTarget, vacationDays)
  }

  recalculateVacationWeeks() {
    let vacationWeeks = ''
    const vacationDays = this.vacationDaysTarget.value
    if (vacationDays) {
      vacationWeeks = +((vacationDays / WORK_DAYS_PER_WEEK) || 0).toFixed(2) // + prefix to drop zeroes
    }

    Helpers.changeInputValue(this.vacationWeeksTarget, vacationWeeks)
  }
}
