import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [ 'yearSelector', 'viewOption' ]

  connect() {
    this.update()
  }

  onChange(event) {
    console.log('onChange')
    this.update()
  }

  update() {
    const viewValue = this.viewOptionTargets.find(option => option.checked).value

    toggle(this.yearSelectorTarget, viewValue != 'overview')
  }
}
