import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'form',
    'endDateField',
    'openEndedCheckbox',
  ]

  connect() {
    this.update()
  }

  update() {
    if (this.openEndedCheckboxTarget.checked) {
      this.endDateFieldTarget.querySelectorAll('input').forEach(input => input.value = '')
    }
    toggle(this.endDateFieldTarget, !this.openEndedCheckboxTarget.checked)
  }
}
