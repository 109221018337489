import { Controller } from '@hotwired/stimulus'
import { ChartHelpers, d3 } from 'utils/chart_helpers'

export default class extends Controller {
  connect() {
    const XAXIS_HEIGHT = 25

    const width = parseInt(this.element.dataset.width)
    const height = parseInt(this.element.dataset.height)

    const svg = d3.select(this.element)
      .append('svg')
      .attr('width', `${width}px`)
      .attr('height', `${height}px`)

    const values = JSON.parse(this.element.dataset.weeklyBreakDeficits)
    const [min, max] = JSON.parse(this.element.dataset.bounds)

    const numWeeks = Math.max(values.length, 52)

    const x = d3.scaleBand()
      .domain(d3.range(0, numWeeks))
      .range([0, width])
      .paddingInner(0.2)

    const y = d3.scaleLinear()
      .domain([0, max])
      .range([height - XAXIS_HEIGHT, 0])

    ChartHelpers.addTooltipHandler(this.element)

    // Background
    ChartHelpers.drawMonthBars(svg, x, y)
    ChartHelpers.drawZeroLine(svg, x, y)

    // Data
    svg.selectAll('.breakRequired')
      .data(values)
      .enter()
      .append('rect')
      .attr('x', (d, i) => x(i))
      .attr('width', x.bandwidth())
      .attr('y', (d, i) => y(d.break_required_duration))
      .attr('height', (d, i) => y(0) - y(d.break_required_duration))
      .attr('class', 'breakRequired bar')

    svg.selectAll('.breakDeficit')
      .data(values)
      .enter()
      .append('rect')
      .attr('x', (d, i) => x(i))
      .attr('width', x.bandwidth())
      .attr('y', (d, i) => y(d.break_required_duration))
      .attr('height', (d, i) => y(0) - y(d.break_deficit_duration))
      .attr('class', (d, i) => `breakDeficit bar ${d.status}`)

    // Hover bars + tooltips
    ChartHelpers.drawHoverBars(svg, x, y, values)

    // X Axis
    ChartHelpers.drawMonthAxis(svg, x, y)
  }
}
