import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'link',
  ]

  connect() {
    this.update()
  }

  update() {
    const checkboxes = [...this.element.querySelectorAll('input[type="checkbox"]')]
    const numSelected = checkboxes.filter(checkbox => checkbox.checked).length
    const label = (numSelected == 0 || numSelected == checkboxes.length) ?
      this.element.dataset.labelAllSelected :
      this.element.dataset.labelNumSelected.replace('$count', numSelected)

    this.linkTarget.textContent = label
  }

  onToggle() {
    const group = event.target.dataset.group
    const checkboxes = [...this.element.querySelectorAll(`input[type="checkbox"][data-group="${group}"]`)]
    const allSelected = checkboxes.every(checkbox => checkbox.checked)
    checkboxes.forEach(checkbox => checkbox.checked = !allSelected)
    this.update()
  }
}
