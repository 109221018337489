.c-manage_user_charts_break-deficits {
    position: relative;
} /* for year selector */

  .c-manage_user_charts_break-deficits .c-manage_user_charts_year-selector {
    display: none;
}

  .c-manage_user_charts_break-deficits:hover .c-manage_user_charts_year-selector {
    display: inline-block;
}

  .c-manage_user_charts_break-deficits .breakRequired {
    fill: #3b82f6;
    opacity: 1;
  }

  .c-manage_user_charts_break-deficits .breakDeficit.good {
      fill: #bfdbfe;
    }

  .c-manage_user_charts_break-deficits .breakDeficit.warning {
      fill: #facc15;
    }

  .c-manage_user_charts_break-deficits .breakDeficit.danger {
      fill: #fecaca;
    }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL3VzZXJzL3VzZXIvY2hhcnRzL2JyZWFrX2RlZmljaXRzX2NvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7SUFBQTtBQUFlLEVBQUUsc0JBQXNCOztFQUVEO0lBQUE7QUFBYTs7RUFDSDtJQUFBO0FBQW1COztFQUVuRTtJQUNFLGFBQThCO0lBQzlCLFVBQVU7RUFDWjs7RUFHRTtNQUNFLGFBQThCO0lBQ2hDOztFQUVBO01BQ0UsYUFBZ0M7SUFDbEM7O0VBRUE7TUFDRSxhQUE2QjtJQUMvQiIsImZpbGUiOiJhcHAvY29tcG9uZW50cy91c2Vycy91c2VyL2NoYXJ0cy9icmVha19kZWZpY2l0c19jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmMtbWFuYWdlX3VzZXJfY2hhcnRzX2JyZWFrLWRlZmljaXRzIHtcbiAgQGFwcGx5IHJlbGF0aXZlOyAvKiBmb3IgeWVhciBzZWxlY3RvciAqL1xuXG4gIC5jLW1hbmFnZV91c2VyX2NoYXJ0c195ZWFyLXNlbGVjdG9yIHsgQGFwcGx5IGhpZGRlbjsgfVxuICAmOmhvdmVyIHsgLmMtbWFuYWdlX3VzZXJfY2hhcnRzX3llYXItc2VsZWN0b3IgeyBAYXBwbHkgaW5saW5lLWJsb2NrOyB9IH1cblxuICAuYnJlYWtSZXF1aXJlZCB7XG4gICAgZmlsbDogdGhlbWUoJ2NvbG9ycy5ibHVlLjUwMCcpO1xuICAgIG9wYWNpdHk6IDE7XG4gIH1cblxuICAuYnJlYWtEZWZpY2l0IHtcbiAgICAmLmdvb2Qge1xuICAgICAgZmlsbDogdGhlbWUoJ2NvbG9ycy5ibHVlLjIwMCcpO1xuICAgIH1cblxuICAgICYud2FybmluZyB7XG4gICAgICBmaWxsOiB0aGVtZSgnY29sb3JzLnllbGxvdy40MDAnKTtcbiAgICB9XG5cbiAgICAmLmRhbmdlciB7XG4gICAgICBmaWxsOiB0aGVtZSgnY29sb3JzLnJlZC4yMDAnKTtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */