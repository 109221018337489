import { Controller } from '@hotwired/stimulus';
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static targets = ['groupModal', 'groupLabel', 'groupSelect']

  connect() {
    // copy disabled information for server-side disabled checkboxes
    this.element.querySelectorAll('[data-flag]').forEach(flag => {
      const upperFlag = this.element.querySelector(`[data-flag='${flag.dataset.upperFlag}']`)
      if (upperFlag) {
        flag.disabled = upperFlag.checked || (flag.dataset.immutable === 'true')
      }
    })
  }

  onFlagChanged(event) {
    const flag = event.target
    this.flagChanged(flag)
  }

  flagChanged(flag) {
    const lowerFlag = this.element.querySelector(`[data-flag='${flag.dataset.lowerFlag}']`)
    if (lowerFlag) {
      // disabled either if this checkbox overwrites (e.g. manage overwrites access)
      // or if it was disabled initially server-side (e.g. this setting is already set in the base role)
      if (flag.checked) {
        lowerFlag.checked = true
      }

      lowerFlag.disabled = flag.checked || (lowerFlag.dataset.immutable === 'true')
      this.flagChanged(lowerFlag)
    }
  }

  update() {
  }

  onOpenGroupModal(event) {
    Helpers.emit(this.groupModalTarget, 'modal:open!')
  }

  onGroupSet(event) {
    const group = event.detail

    this.groupLabelTargets.forEach(label => {
      toggle(label.childNodes[0], !group)
      toggle(label.childNodes[1], !!group)

      if (group) {
        label.childNodes[1].textContent = group['label']
        label.childNodes[1].title = group['label']
      }
    })
  }
}
