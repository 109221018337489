import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'
import DateUtils from 'date_utils'

import Glass from 'glass'

export default class extends Controller {
  static targets = [
    'form',
    'startTime',
    'endTime',
    'date',
    'durationDisplay',
    'projectValue',
    'projectSelect',
    'submitButton',
    'taskValue',
    'taskSelect',
  ]

  connect() {
    const tasksData = this.formTarget.dataset.tasks
    if (tasksData) {
      this.tasks = JSON.parse(tasksData)
    }

    const taskIdsByProjectIdData = this.formTarget.dataset.taskIdsByProjectId
    if (taskIdsByProjectIdData) {
      this.taskIdsByProjectId = JSON.parse(taskIdsByProjectIdData)
    }

    this.update()
    this.goodGuy()
    this.updateProjectTasks()

    this.element.addEventListener('time_slider_field:changed', () => this.update())
  }

  onProjectSelected(event) {
    this.updateProjectTasks()
  }

  onSelectProjectAndTask(event) {
    const link = event.target.closest('a')
    const projectId = link.dataset.projectId
    const taskId = link.dataset.taskId

    this.projectValueTarget.value = projectId
    this.taskValueTarget.value = taskId
    Helpers.emit(this.projectSelectTarget, 'select:update!')
    this.updateProjectTasks()

    this.submitButtonTarget.focus()

    Glass.track('time_entry_form::last_selection_click')
  }

  updateProjectTasks() {
    if (!this.tasks || !this.taskIdsByProjectId) {
      // Projects->Task not enabled
      return
    }

    if (!this.hasProjectValueTarget) {
      // no project dropdown
      return
    }

    const projectTaskIds = this.taskIdsByProjectId[this.selectedProjectId]
    let projectTasks = []

    if(projectTaskIds) {
      projectTasks = projectTaskIds
        .map(taskId => { return { value: taskId, label: this.tasks[taskId] } })
        .sort((a, b) => a.label.localeCompare(b.label))
    }

    // Update options
    Helpers.emit(this.taskSelectTarget, 'select:load!', projectTasks)
  }

  onFormatTime(event) {
    [this.startTimeTarget, this.endTimeTarget].forEach((input) => {
      const parsedTime = DateUtils.parseTime(input.value)
      if (parsedTime) {
        input.value = DateUtils.parseTime(input.value)
      }
    })

    this.update()
  }

  onToggleTimer() {
    Glass.track('time_entry_form::toggle_timer_click')
    this.update()
  }

  onSetEndTimeForTouch() {
    if (!Helpers.deviceHasNativeFormControls()) return
    if (this.startTimeTarget.value == '') return

    // On touch devices, set the end time to the start time
    // when touching the end time field and it is still empty.
    // This is for convenience and keeps users from scrolling
    // through the time input wheels.
    if (this.endTimeTarget.value == '') {
      this.endTimeTarget.value = this.startTimeTarget.value
    }
  }

  update() {
    // timer requested: end time set -> yes, otherwise -> no
    const endTime = DateUtils.parseTime(this.endTimeTarget.value)
    const timerShouldRun = !endTime

    if (timerShouldRun) {
      this.durationDisplayTarget.innerHTML = ''
    } else {
      DateUtils.setDuration(this.durationDisplayTarget, this.calculateDuration())
    }

    this.buttonLabel = timerShouldRun ? this.buttonLabelStart : this.buttonLabelSave
  }

  calculateDuration() {
    const startTime = DateUtils.parseTime(this.startTimeTarget.value)
    const endTime = DateUtils.parseTime(this.endTimeTarget.value)
    return DateUtils.timeDiff(startTime, endTime)
  }

  goodGuy() {
    if (this.endTimeTarget.value || this.isPersisted) {
      this.endTimeTarget.focus()
      this.endTimeTarget.select()
    } else {
      this.startTimeTarget.focus()
      this.startTimeTarget.select()
    }

    // Set defaultValue to empty string for Safari as it
    // resets it to the previous value by default.
    if (Helpers.deviceHasNativeFormControls()) {
      let previousStart = this.startTimeTarget.value
      this.startTimeTarget.defaultValue = ''
      this.startTimeTarget.value = previousStart

      let previousEnd = this.endTimeTarget.value
      this.endTimeTarget.defaultValue = ''
      this.endTimeTarget.value = previousEnd
    }
  }

  get buttonLabelSave() {
    return this.formTarget.getAttribute('data-label-save')
  }

  get buttonLabelStart() {
    return this.formTarget.getAttribute('data-label-start')
  }

  set buttonLabel(label) {
    this.submitButtonTarget.innerHTML = label
  }

  get selectedProjectId() {
    if (!this.hasProjectValueTarget)
      return ''

    return this.projectValueTarget.value
  }

  get selectedTaskId() {
    return this.taskValueTarget.value
  }

  get isPersisted() {
    return this.formTarget.getAttribute('data-persisted') === 'true'
  }
}
