import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'groups',
    'visibilityOptionAll',

    'lastOverallCode',
    'lastClientCode',

    'newClient',
    'existingClient',
    'newClientInput',
  ]

  connect() {
    this.lastUsedProjectCode = this.element.dataset.lastUsedProjectCode
    this.lastUsedProjectCodeByClientId = JSON.parse(this.element.dataset.lastUsedProjectCodeByClientId)

    if (!this.hasNewClientInputTarget || (this.hasNewClientInputTarget && !this.newClientInputTarget.value)) {
      this.clientMode = 'existing'
    } else {
      this.clientMode = 'new'
    }
    this.update()
  }

  update() {
    // New/existing client
    const showExisting = (this.clientMode === 'existing')
    if (this.hasNewClientTarget) {
      this.toggleVisibilityAndInputs(this.newClientTarget, !showExisting)
      this.toggleVisibilityAndInputs(this.existingClientTarget, showExisting)
      if (!showExisting) { this.newClientInputTarget.focus() }
    }

    // Last overall code
    const lastOverallCode = this.lastUsedProjectCode
    if (this.hasLastOverallCodeTarget) {
      this.lastOverallCodeTarget.querySelector('span').textContent = lastOverallCode
      toggle(this.lastOverallCodeTarget, Boolean(lastOverallCode))
    }

    // Last client code
    const clientId = this.element.querySelector('input[name="project[client_id]"]').value
    const lastClientCode = showExisting && this.lastUsedProjectCodeByClientId[clientId]
    if (this.hasLastClientCodeTarget) {
      this.lastClientCodeTarget.querySelector('span').textContent = lastClientCode
      toggle(this.lastClientCodeTarget, Boolean(lastClientCode))
    }

    // Visibility
    const showGroups = !this.visibilityOptionAllTarget.checked
    Helpers.toggleVisibilityAndState(this.groupsTarget, showGroups)
  }

  onNewClient() {
    this.clientMode = 'new'
    this.update()
  }

  onExistingClient() {
    this.clientMode = 'existing'
    this.update()
  }

  toggleVisibilityAndInputs(element, enabled) {
    toggle(element, enabled)
    // disable inputs so they will not be sent
    element.querySelectorAll('input').forEach(i => i.disabled = !enabled)
  }
}
