import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'nav',
    'scrollContainer'
  ]

  connect() {
    this.update()
  }

  onResize(event) {
    this.update()
  }

  update() {
    const scrollable = this.scrollContainerTarget.scrollHeight > this.scrollContainerTarget.clientHeight
    this.navTarget.toggleAttribute('data-scrollable', scrollable)
  }
}
