import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  onAnimationEnd(event) {
    this.remove()
  }

  onRemove(event) {
    this.remove()
  }

  remove() {
    event.target.closest('.flash').remove()
  }

  // data-turbo-permanent causes #flashes to be transferred from old body and added to new body
  // restore the animation state in this case
  onTurboBeforeRender(event) {
    if (!this.isTurboPermanent) return

    this.element.getAnimations().forEach(anim => this.element.dataset.animationCurrentTime = anim.currentTime)
  }

  onTurboRender() {
    if (!this.isTurboPermanent) return

    this.element.getAnimations().forEach(anim => anim.currentTime = this.element.dataset.animationCurrentTime)
  }

  get isTurboPermanent() {
    return this.element.closest('#flashes').hasAttribute('data-turbo-permanent')
  }
}
