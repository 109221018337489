import { Controller } from '@hotwired/stimulus'
import { ChartHelpers, d3 } from 'utils/chart_helpers'

export default class extends Controller {
  connect() {
    const YAXIS_WIDTH = 40
    const XAXIS_HEIGHT = 25
    const PADDING = 10

    const width = parseInt(this.element.dataset.width)
    const height = parseInt(this.element.dataset.height)

    const svg = d3.select(this.element)
      .append('svg')
      .attr('width', `${width}px`)
      .attr('height', `${height}px`)

    const values = JSON.parse(this.element.dataset.monthlyDetails)
    const [min, max] = JSON.parse(this.element.dataset.bounds)

    const numMonths = Math.max(values.length, 12)

    const x = d3.scaleBand()
      .domain(d3.range(0, numMonths))
      .range([YAXIS_WIDTH, width])
      .paddingInner(0.3)

    const y = d3.scaleLinear()
      .domain([min, max])
      .range([height - XAXIS_HEIGHT, PADDING])

    ChartHelpers.addTooltipHandler(this.element)

    // Background
    ChartHelpers.drawMonthBars(svg, x, y)
    ChartHelpers.drawZeroLine(svg, x, y)

    // Data
    const line = d3.line()
      .x((d, i) => x(i) + 0.5 * x.bandwidth())
      .y((d, i) => y(d.balance_in_hours))
      .curve(d3.curveMonotoneX)

    svg.append('path')
      .attr('d', line(values))
      .attr('class', 'line')

    svg
      .selectAll('.point')
      .data(values)
      .enter()
      .append('circle')
      .attr('cx', (d, i) => x(i) + 0.5 * x.bandwidth())
      .attr('cy', (d, i) => y(d.balance_in_hours))
      .attr('class', 'point')
      .attr('r', 2)

    // Hover bars + tooltips
    ChartHelpers.drawHoverBars(svg, x, y, values)

    // X Axis
    ChartHelpers.drawMonthAxis(svg, x, y)

    // Y Axis
    const yAxis = d3.axisLeft()
      .scale(y)
      .ticks(5)
      .tickSize(0)

    svg.append('g')
      .attr('transform', `translate(${YAXIS_WIDTH}, 0)`)
      .call(yAxis)
      .attr('class', 'axis')
  }
}
