import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'
import Glass from 'glass'

export default class extends Controller {
  static targets = [
    'input',
    'icon',
  ]

  // body style to override cursor when sliding is on
  CSS_SLIDER_ACTIVE_BODY_CLASS = '[&_*]:!cursor-ew-resize'

  onMouseDown(event) {
    this.slider = {
      field: this.inputTarget,
      min: this.element.dataset.refMin && document.querySelector(this.element.dataset.refMin),
      max: this.element.dataset.refMax && document.querySelector(this.element.dataset.refMax),
      initial: this.inputTarget.value,
    }
    this.slider.field.focus()

    // if initally end<start, then keep it
    // i.e. start 23, end 1, don't reset start if we slide end
    const max = this.slider.max && this.slider.max.value
    const min = this.slider.min && this.slider.min.value

    let time = DateUtils.parseTime(this.slider.field.value)

    // disable clamp if they are already 'out of bounds'
    const outOfBounds = (max && time && time > max) || (min && time && time < min)
    this.clampEnabled = !outOfBounds

    // determine time value when field is blank
    if (!time) {
      // by default: now
      time = DateUtils.formatHHMM(new Date())

      if (min && min > time) {
        // this is end time (blank), start time is in future -> use start time instead of now
        time = min
      }

      if (max && max < time) {
        // this is start time (blank), end time is in past -> use end time instead of now
        time = max
      }
    }

    this.slider.field.value = time
    this.slider.time = time

    this.slider.start = event.clientX
    document.body.classList.add(this.CSS_SLIDER_ACTIVE_BODY_CLASS)

    // hide tooltip on drag (if exists)
    if (this.iconTarget.getAttribute('data-tooltip')) {
      Helpers.emit(this.iconTarget, 'mouseleave')
    }

    event.preventDefault()
    this.fireChangedEvent()
  }

  onMouseUp(event) {
    if (!this.slider)
      return

    // We clicked, but nothing changed, show tooltip
    if (this.slider.initial === this.slider.field.value) {
      this.iconTarget.setAttribute('data-tooltip', this.element.dataset.hint)
      Helpers.emit(this.iconTarget, 'mouseenter')
    } else {
      this.iconTarget.removeAttribute('data-tooltip')
    }

    Glass.track('time_slider:used', {
      old: this.slider.initial,
      new: this.slider.field.value,
    })

    this.slider = null
    document.body.classList.remove(this.CSS_SLIDER_ACTIVE_BODY_CLASS)

    event.preventDefault()
    this.fireChangedEvent()
  }

  onMouseMove(event) {
    if (!this.slider)
      return

    const pos = event.clientX
    const delta = pos - this.slider.start

    const refSecs = DateUtils.timeInSeconds(this.slider.time)
    const sensitivity = 50
    const deltaSecs = delta * sensitivity

    let res = refSecs + deltaSecs

    // round to 5 minutes
    res = Math.round(res / 300.0) * 300

    // -1:00 -> 23:00
    if (res < 0) { res += (24 * 60 * 60) }

    // 25:00 -> 1:00
    res = res % (24 * 60 * 60)

    const newValue = DateUtils.secondsInTime(res)
    this.slider.field.value = newValue

    // time strings can be compared (0-prefixed)
    // bound the times by the changing value: start overwrites end if start > end and vice versa
    // plus check if value is present (maybe end_time is blank since we want to start a timer)

    if (this.clampEnabled) {
      // use delta (offset from the inital start pos) to determine which 'direction' we go
      // e.g. do not clamp if start is 23 o'clock and we cross the overnight mark to set end to 1 o'clock
      if (delta < 0 && this.slider.min && this.slider.min.value && this.slider.min.value > newValue) {
        this.slider.min.value = newValue
      }

      if (delta > 0 && this.slider.max && this.slider.max.value && this.slider.max.value < newValue) {
        this.slider.max.value = newValue
      }
    }

    this.fireChangedEvent()
  }

  // private
  fireChangedEvent() {
    Helpers.emit(this.inputTarget, 'time_slider_field:changed')
  }
}
