import d3 from './d3_importer.js'
import { Helpers, show, hide } from 'pistachio'

import {
  computePosition,
  offset,
} from '@floating-ui/dom'

export { d3 }

export class ChartHelpers {
   static drawMonthBars(svg, x, y) {
    const monthScale = d3.scaleBand()
      .domain(d3.range(0, 12))
      .range(x.range())

    svg.append('g')
      .selectAll('.monthBar')
      .data(d3.range(12))
      .enter()
      .append('rect')
      .attr('x', (d, i) => monthScale(i))
      .attr('width', monthScale.bandwidth())
      .attr('y', y.range()[1])
      .attr('height', y.range()[0] - y.range()[1])
      .attr('class', 'monthBar')
  }

  static drawMonthAxis(svg, x, y) {
    const monthScale = d3.scaleBand()
      .domain(d3.range(0, 12))
      .range(x.range())

    const monthAxis = d3.axisBottom(monthScale)
      .tickFormat((d, i) => DateUtils.dayjs().set('month', i).format('MMM'))
      .tickPadding(10)
      .tickSize(0)

    svg.append('g')
      .attr('transform', `translate(0, ${y.range()[0]})`)
      .attr('class', 'axis')
      .call(monthAxis)
  }

  static drawZeroLine(svg, x, y) {
    svg.append('line')
      .attr('x1', x.range()[0])
      .attr('x2', x.range()[1])
      .attr('y1', y(0))
      .attr('y2', y(0))
      .attr('class', 'zeroline')
  }

  static drawHoverBars(svg, x, y, values) {
    const bars = svg.selectAll('.hoverBar')
      .data(values)
      .enter()
      .append('a')
        .attr('href', (d) => { if(d.hasOwnProperty('href')) return d.href })
      .append('rect')
        .attr('x', (d, i) => x(i))
        .attr('width', x.bandwidth())
        .attr('y', y.range()[1])
        .attr('height', y.range()[0] - y.range()[1])
        .attr('class', 'hoverBar')
        .on('mouseover', (event) => d3.select(event.target).classed('active', true))
        .on('mouseout', (event) => d3.select(event.target).classed('active', false))
        .on('click', (event) => (event.target.dataset.url && Helpers.visit(event.target.dataset.url)))

    // Assign values to 'data' attributes
    if (values.length) {
      Object.keys(values[0]).forEach(key => {
        bars.attr(`data-${key}`, (d, i) => d[key])
      })
    }
  }

  static addTooltipHandler(element) {
    const tooltip = document.createElement('div')
    tooltip.className = 'tooltip border rounded shadow absolute bg-white text-xs hidden p-2 pointer-events-none whitespace-nowrap'
    element.appendChild(tooltip)

    element.addEventListener('mouseenter', (event) => {
      const data = event.target.getAttribute && event.target.getAttribute('data-chart-tooltip')
      if (data) {
        tooltip.innerHTML = data

        computePosition(event.target, tooltip, {
          placement: 'right',
          middleware: [
            offset(5),
          ],
        })
        .then(({x, y, middlewareData, placement}) => {
          Object.assign(tooltip.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
        })

        show(tooltip)
      }
    }, true) // mouseenter
    element.addEventListener('mouseleave', (event) => {
      hide(tooltip)
    }, true) // mouseenter
  }
}
