export class Timezone {
  static setCookie() {
    if (window.Intl) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      let expires = new Date()
      expires.setDate(expires.getDate() + 30)

      document.cookie = `browser_timezone=${timezone}; expires=${expires.toUTCString()}; path=/; sameSite=strict`
    }
  }
}
