import { Controller } from '@hotwired/stimulus'

import { Helpers } from 'pistachio'

export default class extends Controller {
  static values = {
    date: String,
  }

  static targets = [
    'day',
  ]

  connect() {
    this.update()
  }

  onFocus(event) {
    this.update()
  }

  update() {
    // check if current date is in the range, jump to first day if not
    let currentDatePrsent = this.dayTargets.some(day => {
      return day.dataset.date === this.dateValue
    })

    if (!currentDatePrsent) {
      this.dayTargets[0].click()
    }
  }
}
