import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'name',
    'uploadText',
    'submitButton',
    'fileInput',
    'fileSection',
    'fieldSection',
  ]

  connect() {
    this.update()
  }

  onDrag(event) {
    event.preventDefault()
  }

  onDrop(event) {
    if (event.dataTransfer.types[0] !== 'Files')
      return

    const dt = new DataTransfer()
    dt.items.add(event.dataTransfer.files[0])

    this.fileInputTarget.files = dt.files
    this.update()
    event.preventDefault()
  }

  onFileSelected(event) {
    this.update()
  }

  get requiresFile() {
    return this.hasFileInputTarget && !this.fileInputTarget.value
  }

  update() {
    if (this.hasFileInputTarget && this.fileInputTarget.value) {
      this.nameTarget.value = this.fileInputTarget.value.split(/(\\|\/)/g).pop()
    }

    this.submitButtonTarget.disabled = this.requiresFile

    toggle(this.fileSectionTarget, this.requiresFile)
    toggle(this.fieldSectionTarget, !this.requiresFile)
  }
}
