import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static values = {
    identifier: String,
  }

  connect() {
    const show = !this.hiddenAt
    toggle(this.element, show)
  }

  onHide(event) {
    const data = { hiddenAt: new Date() }
    localStorage.setItem(this.key, JSON.stringify(data))

    toggle(this.element, false)
  }

  get key() {
    return `change-notice:${this.identifierValue}`
  }

  get hiddenAt() {
    const data = JSON.parse(localStorage.getItem(this.key))
    return data?.hiddenAt
  }
}
