import Appsignal from '@appsignal/javascript'
import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network'
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console'
import { plugin as windowEventsPlugin } from `@appsignal/plugin-window-events`

if (process.env.ENABLE_FRONTEND_ERROR_TRACKING == 'true') {
  window.Appsignal = new Appsignal({
    key: /staging/.test(window.location.href) ? '2838adce-81cc-49a6-98b5-881b6b6dca81': 'd7e79fdb-0a89-4ad2-b85d-bf3ed400682e',
    revision: process.env.APP_REVISION,
    ignoreErrors: [
      /XHR request failed \(401\)/,
      /Fetch is aborted/,
      /Not implemented on this platform/, // Likely some Safari extension
    ],
  })

  window.Appsignal.use(breadcrumbsNetworkPlugin({}))
  window.Appsignal.use(breadcrumbsConsolePlugin({}))
  window.Appsignal.use(windowEventsPlugin({}))

  window.Appsignal.addDecorator((span) => {
    return span.setTags({ url: window.location.href });
  })

  // To get a more relevant grouping of errors (as most are just Error),
  // set the error name to <error.name>: <error.message>
  window.Appsignal.addDecorator((span) => {
    const error = span.serialize().error
    return span.setError({
      name: `${error.name}: ${error.message}`,
      message: error.message,
      stack: error.backtrace.join('\n'),
    })
  })
}

// Imports
import '@hotwired/turbo-rails'

import { Hakuna } from '../hakuna'
import { Polyfills, Helpers } from 'pistachio'
import Rails from '@rails/ujs'
import Glass from 'glass'
import '../charts.css';

import { DateUtils } from '../date_utils'
import { Timezone } from '../timezone'

// Do the dance
window.lang = document.querySelector('html').lang
window.DateUtils = DateUtils

Polyfills.checkAndRun(main)

function main(err) {
  require('pistachio/app/components')

  require('../component_styles')
  require('../stimulus_controllers')

  Rails.start()

  Hakuna.ready(event => {
    if (event.detail && event.detail.timing) {
      const timing = event.detail.timing

      // timing is only available for subsequent turbo xhr page loads
      if (Object.keys(timing).length > 0) {
        Glass.trackXHR(timing)
      } else {
        Glass.trackPageLoad()
      }
    } else {
      Glass.trackPageLoad()
    }

    Timezone.setCookie()

    Helpers.pollAndForward()

    if (!Polyfills.browserSupportsAllFeatures()) {
      Glass.track('browser::polyfills_required', Polyfills.featureSupportList())
    }
  })
}

import 'pistachio/app/base'
import '../globals.css'
import '../events.css'
import '../print.css'

import * as images from '../images/**/*'
images.default.map((image) => image)
