import { Controller } from '@hotwired/stimulus'

import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'toggle',
    'checkbox',
  ]

  onToggleAll(event) {
    const check = event.target.checked
    this.checkboxTargets.forEach(checkbox => checkbox.checked = check)
    this.update()
  }

  update() {
    this.toggleTarget.checked = this.checkboxTargets.every(checkbox => checkbox.checked)
  }
}
