import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../../utils/helpers'

export default class extends Controller {
  static targets = [
    'modal',
    'content',
  ]

  connect() {
    this.element.addEventListener('click', (event) => {
      // if we click a button or link inside a data-remote-modal-url element that
      // isn't the element with data-remote-modal-url itself, we don't want to open the modal
      if (event.target.closest('a:not([data-remote-modal-url])') || event.target.closest('button:not([data-remote-modal-url])')) {
        return
      }

      let el = event.target.closest('[data-remote-modal-url]')
      if (!el || !el.getAttribute)
        return

      const url = el.getAttribute('data-remote-modal-url')
      if (!url)
        return

      Helpers.emit(this.modalTarget, 'remote-modal:open', { source: el })

      event.stopPropagation()

      Helpers.emit(this.modalTarget, 'modal:open!', { preload: true })

      Helpers.fetch('get', url).then(response => {
        if(response.ok) {
          response.html.then(html => {
            Helpers.emit(this.modalTarget, 'modal:content!', html)
          })
        }
        else {
          Helpers.emit(this.modalTarget, 'modal:close!')
        }
      })
    }, true) // mousedown
  }
}
