import { Controller } from '@hotwired/stimulus'
import { show, hide } from 'pistachio'

export default class extends Controller {
  static targets = [
    'vacationCalculated',
    'vacationCorrection',
    'vacationTotal',
    'saveButton',
    'savedLabel'
  ]

  connect() {
    this.onCalculateVacation()
  }

  onCalculateVacation() {
    const calculated = parseFloat(this.vacationCalculatedTarget.innerHTML)
    const correction = parseFloat(this.vacationCorrectionTarget.value) || 0

    const sum = calculated + correction
    const total = Math.round(sum * 2) / 2

    this.vacationTotalTarget.innerHTML = +total.toFixed(1)
  }

  onChange() {
    hide(this.savedLabelTarget)
    show(this.saveButtonTarget)
    this.saveButtonTarget.disabled = false
  }

  onSave() {
    this.saveButtonTarget.disabled = true
    hide(this.saveButtonTarget)
    show(this.savedLabelTarget)
  }
}
