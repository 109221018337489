import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    year: Number,
    month: Number,
    urlTemplate: String,
  }

  static targets = [
    'year',
    'month',
    'link',
  ]

  connect() {
    this.year = this.yearValue
    this.update()
  }

  update() {
    this.yearTarget.innerText = this.year

    this.monthTargets.forEach((monthTarget) => {
      // Mark selected month
      let isSelected = this.year == this.yearValue && monthTarget.dataset.month == this.monthValue
      monthTarget.classList.toggle('bg-blue-600', isSelected)
      monthTarget.classList.toggle('text-white', isSelected)

      // Mark current month
      monthTarget.classList.toggle('bg-blue-100', this.year === this.currentYear && monthTarget.dataset.month == this.currentMonth)
    })
  }

  onClickMonth(event) {
    const month = event.target.dataset.month
    const url = this.urlTemplateValue.replace('YEAR', this.year).replace('MONTH', month)

    this.linkTarget.href = url
    this.linkTarget.click()
  }

  previousYear() {
    this.year = this.year - 1

    this.update()
  }

  nextYear() {
    this.year = this.year + 1

    this.update()
  }

  get currentYear() {
    return new Date().getFullYear()
  }

  get currentMonth() {
    return new Date().getMonth() + 1
  }
}
