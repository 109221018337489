import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'form',
    'isOwner',
    'isAdmin',
  ]

  connect() {
    this.update()
  }

  onFormChange() {
    this.update()
  }

  update() {
  }
}
