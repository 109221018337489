import application from './stimulus_application'
import { identifierForContextKey } from '@hotwired/stimulus-webpack-helpers'
import * as componentControllers from './components/**/controller.js'
import * as utilControllers from './utils/*_controller.js'
import * as viewComponentControllers from '../components/**/*_controller.js'

// VIEW COMPONENT
const viewComponentDefinitions = viewComponentControllers.default.map((constructor, index) => {
  const filename = viewComponentControllers.filenames[index]
  const identifier = identifierForContextKey(filename.replace('../components/', '').replace('_component_controller.js', '_controller.js'))
  const controllerConstructor = constructor.default

  return { identifier, controllerConstructor }
})
application.load(viewComponentDefinitions)

// Load controllers definitions from components/
const componentDefinitions = componentControllers.default.map((constructor, index) => {
  const filename = componentControllers.filenames[index]
  const identifier = identifierForContextKey(filename.replace('./components/', '').replace('/controller.js', '') + '_controller.js')
  const controllerConstructor = constructor.default

  return { identifier, controllerConstructor }
})
application.load(componentDefinitions)

// Load controllers definitions from utils/
const utilDefinitions = utilControllers.default.map((constructor, index) => {
  const filename = utilControllers.filenames[index]
  const identifier = identifierForContextKey(filename.replace('./utils/', ''))
  const controllerConstructor = constructor.default

  return { identifier, controllerConstructor }
})
application.load(utilDefinitions)

export default application
