.c-search-field {
  position: relative;
}body.sm .c-search-field {
  width: 100%;
}

  .c-search-field__dropdown {
    margin: 4px 0 0 0;
    width: 100%;
    max-width: 350px;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vc2VhcmNoX2ZpZWxkX2NvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQkFBa0I7QUFTcEIsQ0FQRTtFQUFBO0FBQWdCOztFQUVoQjtJQUNFLGlCQUFpQjtJQUNqQixXQUFXO0lBQ1gsZ0JBQWdCO0VBQ2xCIiwiZmlsZSI6Ii4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vc2VhcmNoX2ZpZWxkX2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYy1zZWFyY2gtZmllbGQge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG5cbiAgQGFwcGx5IHNtOnctZnVsbDtcblxuICAmX19kcm9wZG93biB7XG4gICAgbWFyZ2luOiA0cHggMCAwIDA7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgbWF4LXdpZHRoOiAzNTBweDtcbiAgfVxufVxuIl19 */