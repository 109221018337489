import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'picker',
    'link',
  ]

  onToggle(event) {
    Helpers.emit(this.pickerTarget, 'date:toggle!')
  }

  onCalendarDate(event) {
    const date = event.detail
    const url = this.element.dataset.calendarUrl.replace('DATE', date)
    this.linkTarget.href = url
    this.linkTarget.click()
  }
}
