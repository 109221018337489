import { Controller } from '@hotwired/stimulus'
import { toggle } from "pistachio";

export default class extends Controller {
  static targets = [
    'isPartialField',
    'partOfDayField',

    'partialSubForm',
  ]

  connect() {
    this.update()
  }

  onSelectPeriod(event) {
    const selectedOption = event.target.options[event.target.selectedIndex]

    this.partOfDayFieldTarget.value = selectedOption.dataset.partOfDay
    this.isPartialFieldTarget.value = selectedOption.dataset.isPartial

    this.update()
  }

  update() {
    toggle(this.partialSubFormTarget, this.isPartialFieldTarget.value === 'true')
  }
}
