import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static values = {
    userId: Number,
  }

  connect() {
    Helpers.emit(this.element, 'staff_calendar:refresh!', { userIds: [this.userIdValue] })
  }
}
