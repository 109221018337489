import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'
import { marked } from 'marked'

export default class extends Controller {
  static values = {
    streamUrl: String,
    flagUrl: String,
  }

  static targets = [
    'form',
    'input',
    'iconSearch',
    'iconLoading',
    'loading',
    'answer',

    'result',

    'flag',
    'flagBar',
  ]

  connect() {
    this.loading = false
    this.flagged = false

    this.update()
  }

  update() {
    toggle(this.iconSearchTarget, !this.loading)
    toggle(this.iconLoadingTarget, this.loading)

    this.answerTarget.querySelectorAll('.is-loading').forEach((el) => el.classList.remove('is-loading'))
    this.answerTarget.querySelectorAll('p:last-child').forEach((el) => el.classList.toggle('is-loading', this.loading))

    const showFlag = !!(this.flagTarget.dataset.question && this.flagTarget.dataset.answer)
    toggle(this.flagBarTarget, showFlag)
    this.flagTarget.classList.toggle('!text-red-500', this.flagged)
  }

  async streamData() {
    if (this.abortController) { this.abortController.abort() }
    this.abortController = new AbortController()

    // clear any previous answer
    this.loading = true
    this.answerTarget.innerHTML = '<p></p>' // empty p loading indicator


    this.flagged = false
    delete this.flagTarget.dataset.question
    delete this.flagTarget.dataset.answer

    this.update()

    const response = await fetch(this.streamUrlValue, {
      method: 'POST',
      body: Helpers.getFormDataForXHR(this.formTarget),
      signal: this.abortController.signal
    })

    const reader = response.body.getReader()

    let answer = ''
    while (true) {
      const { done, value } = await reader.read()
      if (done)
        break

      const chunk = new TextDecoder('utf-8').decode(value)
      answer += chunk

      this.answerTarget.innerHTML = this.convertMarkdown(answer)
      this.update()
    }

    // for flagging
    this.flagTarget.dataset.question = this.inputTarget.value
    this.flagTarget.dataset.answer = this.answerTarget.innerHTML

    this.loading = false
    this.update()
  }

  onSubmit(event) {
    event.preventDefault()
    toggle(this.resultTarget, true)
    this.streamData()
  }

  onClearInput(event) {
    this.inputTarget.value = ''
    this.inputTarget.focus()
  }

  onFlag(event) {
    const question = this.flagTarget.dataset.question
    const answer = this.flagTarget.dataset.answer

    fetch(this.flagUrlValue, {
      method: 'POST',
      body: new URLSearchParams({
        question: question,
        answer: answer,
      }),
    }).then(() => {
      this.flagged = true
      this.update()
    })
  }

  escapeHTML(html) {
    var text = document.createTextNode(html);
    var div = document.createElement('div');
    div.appendChild(text);
    return div.innerHTML;
  }

  convertMarkdown(text) {
    const renderer = new marked.Renderer()
    const linkRenderer = renderer.link

    renderer.link = (href, title, text) => {
      const html = linkRenderer.call(renderer, href, title, text)
      return html.replace(/^<a /, '<a target="_blank" ')
    }

    // escapeHTML to avoid XSS
    return marked(this.escapeHTML(text), { renderer: renderer })
  }
}
