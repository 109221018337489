import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'
import DateUtils from 'date_utils'

export default class extends Controller {
  static targets = [
    'dateField',
    'vacationDayWorth',
    'vacationDurationWorth',
    'employmentSummary',
  ]

  connect() {
    this.employments = JSON.parse(this.element.dataset.employments)
    this.update()
  }

  onChange(event) {
    this.update()
  }

  onApplyVacationDayWorth(event) {
    const duration = this.vacationDurationWorthTarget.innerText
    Helpers.changeInputValue(this.vacationDayWorthTarget, duration)
  }

  update() {
    const date = this.dateFieldTarget.value
    const employment = this.employments.find(e => {
      return (date >= e['start_date'] && (e['end_date'] == null || date <= e['end_date']))
    })

    if (employment) {
      this.employmentSummaryTarget.innerHTML = employment['summary_html']
      this.vacationDurationWorthTarget.innerText = DateUtils.secondsToDuration(employment['target_time_per_day'])
    } else {
      this.employmentSummaryTarget.innerHTML = this.element.dataset.noEmploymentSummaryHtml
      this.vacationDurationWorthTarget.innerText = DateUtils.secondsToDuration(0)
    }
  }
}
