import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'name',
  ]

  onFileSelected(event) {
    const fileName = event.target.value.split(/(\\|\/)/g).pop();
    if (!this.nameTarget.value) {
      this.nameTarget.value = fileName
    }
  }
}
