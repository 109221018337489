import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle, isVisible } from 'pistachio'

export default class extends Controller {
  static targets = [
    'absenceType',
    'startDate',
    'endDate',

    'halfDayFields',
    'partOfDayFields',

    'startHalfDay',
    'endHalfDay',

    'note',

    'useCustomDurationCheckbox',
    'customDurationFields',

    'isRecurringCheckbox',
    'recurringFields',
    'recurringUntilDate',

    'skipApproval',
    'fullAccessButton',
    'requestButton',
    'fullAccessDestroyButton',
    'requestDestroyButton',
  ]

  connect() {
    this.showAdvancedOptions = this.isInitiallyShowAdvancedOptions
    Helpers.emit(this.absenceTypeTarget, 'select:focus!')
    this.update()
  }

  onShowAdvancedOptions() {
    this.showAdvancedOptions = true
    this.update()
  }

  onSkipApprovalToggle(event) {
    // in case destroy confirm is currently open (the user clicked on the trash can)
    // make sure we close all confirms, not the cleanest solution but probably the most pragmatic
    if (this.hasFullAccessDestroyButtonTarget) {
      const confirmEl = this.fullAccessDestroyButtonTarget.querySelector('[data-pistachio--toggle-target="target"]')
      toggle(confirmEl, false)
    }

    if (this.hasRequestDestroyButtonTarget) {
      const confirmEl = this.requestDestroyButtonTarget.querySelector('[data-pistachio--toggle-target="target"]')
      toggle(confirmEl, false)
    }
  }

  onPartOfDaySet(event) {
    if (!event.detail) return

    this.startHalfDayTarget.checked = !!event.detail.data['start-half-day']
    this.endHalfDayTarget.checked = !!event.detail.data['end-half-day']
  }

  update() {
    // Toggle fields for either part of day (single day) or half day (multi day)
    const isMultiDay = this.startDateTarget.value !== this.endDateTarget.value
    Helpers.toggleVisibilityAndState(this.partOfDayFieldsTarget, !isMultiDay)
    Helpers.toggleVisibilityAndState(this.halfDayFieldsTarget, isMultiDay)

    // Toggle manual credit duration field when manual credit is selected
    if (this.hasCustomDurationFieldsTarget) {
      const useCustomDuration = this.useCustomDurationCheckboxTarget.checked
      Helpers.toggleVisibilityAndState(this.customDurationFieldsTarget, useCustomDuration)
    }

    // Toggle recurring form
    if (this.hasRecurringFieldsTarget) {
      const isRecurring = this.isRecurringCheckboxTarget.checked
      Helpers.toggleVisibilityAndState(this.recurringFieldsTarget, isRecurring)
    }

    // Determine which button to show as primary action
    if (this.hasSkipApprovalTarget) {
      this.toggleSubmit(this.fullAccessButtonTarget, this.skipApprovalTarget.checked)
      this.toggleSubmit(this.requestButtonTarget, !this.skipApprovalTarget.checked)

      if (this.hasFullAccessDestroyButtonTarget) {
        toggle(this.fullAccessDestroyButtonTarget, this.skipApprovalTarget.checked)
      }
      if (this.hasRequestDestroyButtonTarget) {
        toggle(this.requestDestroyButtonTarget, !this.skipApprovalTarget.checked)
      }
    }
  }

  // ensure only one submit exists at a time (so CMD+ENTER chooses correct submit, display is ignored)
  toggleSubmit(element, show) {
    toggle(element, show)
    element.type = show ? 'submit' : 'button'
  }

  get isInitiallyShowAdvancedOptions() {
    if (this.hasUseCustomDurationCheckboxTarget && this.useCustomDurationCheckboxTarget.checked) return true
    if (this.hasIsRecurringCheckboxTarget && this.isRecurringCheckboxTarget.checked) return true

    return false
  }
}
