.c-dropdown {
  display: inline-block;
}

  .c-dropdown .c-dropdown__toggle {
    width: inherit;
    cursor: pointer;
  }

  .c-dropdown__window {
  position: absolute;
  top: 0px;
  left: 0px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

    z-index: calc(var(--z-index-top) + 1);
}

  .c-dropdown__link a,
    .c-dropdown__link .button_to > [type="submit"] {
  display: block;
  width: 100%;
  text-align: left;
  text-transform: none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
  line-height: 1.25rem;
}

  body.lg .c-dropdown__link a,body.lg 
    .c-dropdown__link .button_to > [type="submit"] {
  white-space: nowrap;
}

  .c-dropdown__link a:hover, .c-dropdown__link .button_to > [type="submit"]:hover {
        color: white;
        background: #2563eb;
      }

  .c-dropdown__link a.disabled, .c-dropdown__link .button_to > [type="submit"].disabled {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
  cursor: not-allowed;
}

  .c-dropdown__link .button_to {
  display: block;
  width: 100%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vZHJvcGRvd25fY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHFCQUFxQjtBQXdDdkI7O0VBdENFO0lBQ0UsY0FBYztJQUNkLGVBQWU7RUFDakI7O0VBR0U7RUFBQSxrQkFBNEI7RUFBNUIsUUFBNEI7RUFBNUIsU0FBNEI7RUFDNUIsb0JBQVc7RUFBWCx1QkFBVzs7SUFFWDtBQUg0Qjs7RUFTMUI7O0VBQUEsY0FBbUI7RUFBbkIsV0FBbUI7RUFDbkIsZ0JBQWdCO0VBQ2hCLG9CQUFrQjtFQUNsQixrQkFBZ0I7RUFBaEIsbUJBQWdCO0VBQWhCLG1CQUFnQjtFQUFoQixzQkFBZ0I7RUFDaEIsbUJBQTRCO0VBQTVCLG9CQUE0QjtFQUE1Qiw2Q0FBNEI7RUFDNUI7QUFMbUI7O0VBTW5COztFQUFBO0FBQTJCOztFQUUzQjtRQUNFLFlBQVk7UUFDWixtQkFBb0M7TUFDdEM7O0VBR0U7RUFBQSxvQkFBb0I7RUFBcEIsZ0RBQW9CO0VBQ3BCO0FBRG9COztFQU10QjtFQUFBLGNBQW1CO0VBQW5CO0FBQW1CIiwiZmlsZSI6Ii4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vZHJvcGRvd25fY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jLWRyb3Bkb3duIHtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuXG4gIC5jLWRyb3Bkb3duX190b2dnbGUge1xuICAgIHdpZHRoOiBpbmhlcml0O1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgfVxuXG4gICZfX3dpbmRvdyB7XG4gICAgQGFwcGx5IGFic29sdXRlIHRvcC0wIGxlZnQtMDtcbiAgICBAYXBwbHkgcHktMTtcblxuICAgIHotaW5kZXg6IGNhbGModmFyKC0tei1pbmRleC10b3ApICsgMSk7XG4gIH1cblxuICAmX19saW5rIHtcbiAgICBhLFxuICAgIC5idXR0b25fdG8gPiBbdHlwZT1cInN1Ym1pdFwiXSB7XG4gICAgICBAYXBwbHkgYmxvY2sgdy1mdWxsO1xuICAgICAgQGFwcGx5IHRleHQtbGVmdDtcbiAgICAgIEBhcHBseSBub3JtYWwtY2FzZTtcbiAgICAgIEBhcHBseSBweC00IHB5LTI7XG4gICAgICBAYXBwbHkgdGV4dC1zbSB0ZXh0LWdyYXktNzAwO1xuICAgICAgQGFwcGx5IGxlYWRpbmctNTtcbiAgICAgIEBhcHBseSBsZzp3aGl0ZXNwYWNlLW5vd3JhcDtcblxuICAgICAgJjpob3ZlciB7XG4gICAgICAgIGNvbG9yOiB3aGl0ZTtcbiAgICAgICAgYmFja2dyb3VuZDogdGhlbWUoJ2NvbG9ycy5ibHVlLjYwMCcpO1xuICAgICAgfVxuXG4gICAgICAmLmRpc2FibGVkIHtcbiAgICAgICAgQGFwcGx5IHRleHQtZ3JheS00MDA7XG4gICAgICAgIEBhcHBseSBjdXJzb3Itbm90LWFsbG93ZWQ7XG4gICAgICB9XG4gICAgfVxuXG4gICAgLmJ1dHRvbl90byB7XG4gICAgICBAYXBwbHkgYmxvY2sgdy1mdWxsO1xuICAgIH1cbiAgfVxufVxuIl19 */