import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'input',
    'link',
  ]

  onShowPicker(event) {
    this.inputTarget.focus() // show native picker
  }

  onChange(event) {
    const [year, month] = this.inputTarget.value.split('-')

    const url = this.element.dataset.calendarUrl.replace('YEAR', year).replace('MONTH', month)
    this.linkTarget.href = url
    this.linkTarget.click()
  }
}
