import { Controller } from '@hotwired/stimulus'
import DateUtils from 'date_utils'

export default class extends Controller {
  static targets = [
    'startTime',
    'endTime',
    'breakDuration',
  ]

  onShiftTypeSet(event) {
    const isSelected = event.detail?.data ? true : false

    this.startTimeTarget.disabled = !isSelected
    this.endTimeTarget.disabled = !isSelected
    this.breakDurationTarget.disabled = !isSelected
  }

  onShiftTypeChanged(event) {
    const shiftType = event.detail?.data

    this.startTimeTarget.value = shiftType?.start_time || ''
    this.endTimeTarget.value = shiftType?.end_time || ''
    this.breakDurationTarget.value = shiftType?.break_duration || ''
    this.breakDurationTarget.dispatchEvent(new Event('change', { bubbles: false }))
  }

  onTimeBlur(event) {
    const input = event.target
    const parsedTime = DateUtils.parseTime(input.value)
    input.value = parsedTime ? parsedTime : ''
  }

  update() {
  }
}
