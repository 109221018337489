import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'otherSourceField',
  ]

  onSourceSet(event) {
    const option = event.detail
    const showOtherSource = option ? option.value === 'other' : false
    Helpers.toggleVisibilityAndState(this.otherSourceFieldTarget, showOtherSource)
  }
}
