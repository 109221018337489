import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = ['type', 'month', 'year', 'custom']

  connect() {
  }

  update() {
    const type = this.typeTarget.value

    this.showAndEnable(this.monthTarget, type === 'month')
    this.showAndEnable(this.yearTarget, type === 'month' || type === 'year')
    this.showAndEnable(this.customTarget, type === 'custom', 'flex')
  }

  showAndEnable(element, on, defaultDisplay = null) {
    toggle(element, on, defaultDisplay)
    element.querySelectorAll('input').forEach(input => input.disabled = !on)
  }
}
