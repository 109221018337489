import { Controller } from '@hotwired/stimulus'
import { show, hide } from 'pistachio'

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', event => {
      if (event.target.method != 'get') {
        return
      }

      if (!event.target.dataset.remote) {
        return
      }

      event.preventDefault()
      const query = new URLSearchParams(new FormData(event.target)).toString()
      Turbo.visit(event.target.action + '?' + query)
    }, true)
  }
}
