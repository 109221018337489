import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'notificationEnabled',
    'notificationEmails',
  ]

  connect() {
    this.update()
  }

  update() {
    for (let i = 0; i < this.notificationEnabledTargets.length; i++) {
      const notificationEnabled = this.notificationEnabledTargets[i];
      const notificationEmails = this.notificationEmailsTargets[i];

      // enabled required for setting email flag
      if (notificationEnabled.checked) {
        notificationEmails.disabled = false
      } else {
        notificationEmails.disabled = true
        notificationEmails.checked = false
      }
    }
  }

}
